import React from "react"
import { Link } from "gatsby"
import { useLocaleContext } from "../context"

export default function Custom(props) {
  const { locale } = useLocaleContext()
  const { to, ...rest } = props
  const url = locale === "es" ? (to == "/" ? locale : `/${locale}`) : ""
  return (
    <Link to={`${to}${url}`} {...rest}>
      {props.children}
    </Link>
  )
}
