import React from "react"
import { Link } from "gatsby"
import {
  FaFacebookSquare as Facebook,
  FaInstagram as Instagram,
} from "react-icons/fa"
import logo2 from "../images/logo2.svg"
import { useLocaleContext } from "../context"

export default function Footer({ data = {} }) {
  const { locale } = useLocaleContext()
  const isSpanish = locale === "es"
  const { phones, facebook, instagram } = data
  return (
    <section className="footer-bg bg-primary text-white">
      <footer>
        <div className="container mx-auto grid sm:grid-cols-2 py-12 px-5 items-end">
          <div className="text-base">
            <Link to="/" className="mb-5 block">
              <img src={logo2} alt="" style={{ height: 70 }} />
            </Link>
            <div className="space-y-2">
              {phones.map(({ phone }, i) => (
                <p key={i}>{phone}</p>
              ))}
            </div>
          </div>
          <div className="text-left mt-5 sm:mt-0 sm:text-right">
            <ul className="flex items-center justify-start sm:justify-end text-2xl space-x-3">
              {facebook && (
                <li>
                  <a href={facebook} target="blank">
                    <Facebook />
                  </a>
                </li>
              )}
              {instagram && (
                <li>
                  <a href={instagram} target="blank">
                    <Instagram />
                  </a>
                </li>
              )}
            </ul>
            <ul className="flex items-center justify-start sm:justify-end text-white my-3 flex-wrap space-x-3 text-sm">
              <li>
                <Link to="/contact">{isSpanish ? "Contacto" : "Contact"}</Link>
              </li>
              <li>
                <Link to="/privacy">
                  {isSpanish ? "Privacidad" : "Privacy Policy"}
                </Link>
              </li>
            </ul>
            <p className="text-xs opacity-75">
              © {new Date().getFullYear()} -{" "}
              {isSpanish
                ? "Todos los derechos reservados"
                : "All Rights Reserved"}
            </p>
          </div>
        </div>
      </footer>
    </section>
  )
}
