import React from "react"
import CustomLink from "../components/customLink"

export default function Button({
  children,
  onClick = () => {},
  tail = "",
  to = "",
}) {
  if (to)
    return (
      <CustomLink
        className={`py-3 px-5 text-orange-900 bg-orange-200 rounded-full hover:bg-orange-300 ${tail}`}
        onClick={onClick}
        to={to}
      >
        {children}
      </CustomLink>
    )
  return (
    <button
      className={`h-12 px-5 text-orange-900 bg-orange-200 rounded-full hover:bg-orange-300 ${tail}`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
