import PropTypes from "prop-types"
import React from "react"
import Button from "../components/button"
import logo from "../images/logo.svg"
import CustomLink from "../components/customLink"
import Language from "../components/language"
import { useLocaleContext } from "../context"

function Menu() {
  const { locale } = useLocaleContext()
  const isSpanish = locale === "es"
  return (
    <ul className="flex space-x-3 text-blue-900 main-menu">
      <li>
        <CustomLink
          to="/services"
          activeClassName="text-orange-900 underline"
          className="hover:underline whitespace-no-wrap"
        >
          {isSpanish ? "Servicios" : "Services"}
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/corporate"
          activeClassName="text-orange-900 underline"
          className="hover:underline whitespace-no-wrap"
        >
          {isSpanish ? "Corporativo" : "Corporate"}
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/events"
          activeClassName="text-orange-900 underline"
          className="hover:underline whitespace-no-wrap"
        >
          {isSpanish ? "Eventos" : "Events"}
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/about"
          activeClassName="text-orange-900 underline"
          className="hover:underline whitespace-no-wrap"
        >
          {isSpanish ? "Nosotros" : "About us"}
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/staff"
          activeClassName="text-orange-900 underline"
          className="hover:underline whitespace-no-wrap"
        >
          {isSpanish ? "Miembros" : "Staff"}
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/reviews"
          activeClassName="text-orange-900 underline"
          className="hover:underline whitespace-no-wrap"
        >
          {isSpanish ? "Testimonios" : "Reviews"}
        </CustomLink>
      </li>
      <li>
        <CustomLink
          to="/gallery"
          activeClassName="text-orange-900 underline"
          className="hover:underline whitespace-no-wrap"
        >
          {isSpanish ? "Galería" : "Gallery"}
        </CustomLink>
      </li>
    </ul>
  )
}

const Header = () => {
  const { locale } = useLocaleContext()
  const isSpanish = locale === "es"

  return (
    <section className="absolute top-0 left-0 w-full z-20">
      <header>
        <div className="container mx-auto px-5 py-3">
          <nav className="md:flex items-center justify-between hidden">
            <CustomLink to="/" className="text-primary text-2xl">
              <img src={logo} alt="" style={{ height: 60 }} />
            </CustomLink>
            <Menu />
            <div>
              <Button to="/contact">
                {isSpanish ? "Contacto" : "Contact us"}
              </Button>
              <Language />
            </div>
          </nav>
          <div className="md:hidden items-center justify-between flex">
            <CustomLink to="/" className="text-primary font-black text-2xl">
              <img src={logo} alt="" style={{ height: 60 }} />
            </CustomLink>
            <div>
              <Button to="/contact">
                {isSpanish ? "Contacto" : "Contact us"}
              </Button>
              <Language />
            </div>
          </div>
          <div className="md:hidden overflow-x-auto py-3">
            <Menu />
          </div>
        </div>
      </header>
    </section>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
