import React from "react"
import { useLocaleContext } from "../context"
import { navigate } from "gatsby"

export default function Language() {
  const { locale, setLocale } = useLocaleContext()
  const handleSetLocale = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("sanalife-lang", locale === "" ? "es" : "")
      let url = window.location.href
      let path = window.location.path
      console.log({ path })
      url =
        locale === ""
          ? path == "/"
            ? "es"
            : url + "/es"
          : url.replace("/es", "")
      window.location.href = url
      // navigate(url, {
      //   replace: true,
      // })
    }
  }

  return (
    <button className="ml-5 uppercase" onClick={handleSetLocale}>
      {locale === "" ? "es" : "en"}
    </button>
  )
}
