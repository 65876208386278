import React from "react"
import CustomLink from "./customLink"

export default function TopBar({ barText }) {
  return (
    <div className="bg-primary py-5">
      <div className="container mx-auto px-5 text-lg text-white text-center">
        <CustomLink to="/alerts">{barText}</CustomLink>
      </div>
    </div>
  )
}
