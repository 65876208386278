import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "../components/footer"
import Header from "./header"
import TopBar from "./topBar"

const Layout = ({ children, footer = {}, barText = "", barLink }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {barText && <TopBar barLink={barLink} barText={barText} />}
      <div className="min-h-screen relative">
        <Header />
        <div>
          <main className="text-lg">{children}</main>
        </div>
        <Footer data={footer} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
